import React from "react";
import { Layout } from "~/components";
import { chardhamLinks } from "~/constants/links";
import { EkDham } from "~/views";

const HelitaxiiForPilgrimage: React.FunctionComponent = () => (
  <Layout title="HeliTaxii Pilgrimage Yatra" links={chardhamLinks}>
    <EkDham />
  </Layout>
);

export default HelitaxiiForPilgrimage;
